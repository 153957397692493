import { IMetricDefinition } from "../IMetricDefinition";
import { MedicalResourceType } from "next-shared/src/types/types";

export const neckCircumferenceLOINC = "56074-8";
export const neckCircumferenceDisplayName = "Neck Circumference";
export const neckCircumferenceUOM = "cm";

const neckCircumferenceDefinition: IMetricDefinition = {
  type: MedicalResourceType.NeckCircumference,
  displayName: neckCircumferenceDisplayName,
  category: "vital-signs",

  components: [
    {
      codes: [neckCircumferenceLOINC],
      displayName: neckCircumferenceDisplayName,
    },
  ],
};

export default neckCircumferenceDefinition;
