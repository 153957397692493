import { IAustralianState } from "next-shared/src/helpers/constants/geography";

export const australianStates: IAustralianState[] = [
  {
    name: "Australian Capital Territory",
    abbreviation: "ACT",
  },
  {
    name: "New South Wales",
    abbreviation: "NSW",
  },
  {
    name: "Northern Territory",
    abbreviation: "NT",
  },
  {
    name: "Queensland",
    abbreviation: "QLD",
  },
  {
    name: "South Australia",
    abbreviation: "SA",
  },
  {
    name: "Tasmania",
    abbreviation: "TAS",
  },
  {
    name: "Victoria",
    abbreviation: "VIC",
  },
  {
    name: "Western Australia",
    abbreviation: "WA",
  },
];
