/**
 * unix timestamp type
 */
export type unixTimestamp = number;
/**
 * Javascript Unix Timestamp - unix timestamp in milliseconds instead of seconds
 */
export type unixTimestampMs = number;
export type ISODate = string; // YYYY-MM-DD
// migrated from WLH...
export type jsonDate = number | string;

export const ISODATE_FORMAT = "YYYY-MM-DD";
export const ISODATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const DEFAULT_DATETIME_FORMAT = "DD MMMM YYYY";

/**
 * Used in event occurrences. Special string that's 7 "char" long. Each behaves like a binary bit,
 * 0 for true, 1 for false. And they are in this order - SMTWTFS (Sunday start)
 * i.e. 0111110 - the days of week that is 'active' is Monday to Friday.
 */
export type TDayOfWeekBits = string;
