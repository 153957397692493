import { IsNumber, IsString, IsOptional } from "class-validator";
import { EDateFormat } from "../types/EDateFormat";
import { formatUnixDate } from "../util/datetimeConversion";
import { unixTimestamp } from "../types/dateTypes";
import {
  PersistableModel,
  ISerializedPersistableModel,
} from "./PersistableModel";

export interface IBaseCarePlanSnapshot extends ISerializedPersistableModel {
  carePlanObservationId?: string;
  collectedAt: unixTimestamp;
}

/**
 * Base care plan snapshot that each snapshot model should implement.
 * Contains the shared props for these snapshot models
 * @class
 */
export class BaseCarePlanSnapshot extends PersistableModel {
  @IsString()
  @IsOptional()
  carePlanObservationId?: string;

  @IsNumber()
  collectedAt: unixTimestamp;

  constructor(data: IBaseCarePlanSnapshot) {
    super(data);
    this.carePlanObservationId = data.carePlanObservationId;
    this.collectedAt = data.collectedAt;
  }

  public serialize(): IBaseCarePlanSnapshot {
    const data = super.serialize();

    return {
      ...data,
      carePlanObservationId: this.carePlanObservationId,
      collectedAt: this.collectedAt,
    };
  }

  public toTemplate() {
    return {
      ...this.serialize(),
      ...super.toTemplate(),
      collectedAt: formatUnixDate(
        this.collectedAt,
        EDateFormat.AustraliaDateFormat,
      ),
    };
  }
}
