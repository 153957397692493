// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wpYMNB5wC3QFgr9zpVYw.QKpztV1kKwjAjZy3vJc7{max-width:100%}.wpYMNB5wC3QFgr9zpVYw.hAcXizlhLbz3jH1WbvQN{width:100%}", "",{"version":3,"sources":["webpack://./../next-react/src/components/generic/Img/styles.scss"],"names":[],"mappings":"AAEE,2CACE,cAAA,CAGF,2CACE,UAAA","sourcesContent":["$theme: next;\n.image {\n  &.-responsive {\n    max-width: 100%;\n  }\n\n  &.-fullwidth {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "wpYMNB5wC3QFgr9zpVYw",
	"-responsive": "QKpztV1kKwjAjZy3vJc7",
	"-fullwidth": "hAcXizlhLbz3jH1WbvQN"
};
export default ___CSS_LOADER_EXPORT___;
