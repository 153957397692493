// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".l_6xp8iIrHGpJeId7IWc{max-width:800px;margin:0 auto}", "",{"version":3,"sources":["webpack://./../next-react/src/components/forms/Form/components/FormInner/styles.scss"],"names":[],"mappings":"AAIA,sBACE,eAAA,CACA,aAAA","sourcesContent":["$theme: next;\n@import \"../../../../../scss/mixins/breakpoints.scss\";\n@import \"../../../../../scss/variables.scss\";\n\n.FormInner {\n  max-width: 800px;\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FormInner": "l_6xp8iIrHGpJeId7IWc"
};
export default ___CSS_LOADER_EXPORT___;
