import { IMetricDefinition } from "../IMetricDefinition";
import { MedicalResourceType } from "next-shared/src/types/types";

export const oxygenSaturationLOINC = "2710-2";
export const oxygenSaturationDisplayName = "Oxygen Saturation";
export const oxygenSaturationUOM = "%";

const oxygenSaturationDefinition: IMetricDefinition = {
  type: MedicalResourceType.OxygenSaturation,
  displayName: oxygenSaturationDisplayName,
  category: "vital-signs",

  components: [
    {
      codes: [oxygenSaturationLOINC],
      displayName: oxygenSaturationDisplayName,
    },
  ],
};

export default oxygenSaturationDefinition;
