import $ from "jquery";
import { touchAvailable } from "./touch";

const $nav = $(".sideNav");
const $navNav = $(".sideNav_nav");
const $navBrand = $(".sideNav_brand", $nav);

console.assert($nav.length === 1, "missing $nav");
console.assert($navNav.length === 1, "missing $navNav");
console.assert($navBrand.length === 1, "missing $navBrand");

// HACK list of clinics we want to disable navigation for.
const navDisabledClinicsSlugs = ["connect-ivf-by-next-practice"];

// check if class for this clinic is present. We add the clinic slug to body element
const skipNavToggle =
  navDisabledClinicsSlugs.filter((slug) => {
    return $(`.page-${slug}`).length > 0;
  }).length > 0;

if (skipNavToggle) {
  console.warn("Skipping navigation toggle");
}

function toggleSubMenu(shouldOpen = false) {
  if (skipNavToggle === true) return;
  // If no state pass, toggle based on whether it's currently open
  shouldOpen =
    typeof shouldOpen === "undefined" ? !$nav.hasClass("-open") : shouldOpen;
  // Allow a 'forceOpen' attribute to override the toggling behaviour
  shouldOpen =
    typeof $nav.attr("forceOpen") !== "undefined" ? true : shouldOpen;

  if (shouldOpen) {
    $nav.addClass("-open");
  } else {
    $nav.removeClass("-open");
  }
}

if (touchAvailable()) {
  // Tablets viewing desktop layout
  $navBrand.click(function () {
    toggleSubMenu();
  });
} else {
  $navNav.mouseenter(function () {
    toggleSubMenu(true);
  });
  $navNav.mouseleave(function () {
    toggleSubMenu(false);
  });
}

// toggle using mobile nav
const $webView = $(".webView");
const $mobileNavMenuButton = $(".mobileNav_menu");

console.assert($webView.length === 1, "missing $webView");
console.assert(
  $mobileNavMenuButton.length === 1,
  "missing $mobileNavMenuButton",
);

$mobileNavMenuButton.click(() => {
  if ($webView.hasClass("-showNav")) {
    $webView.removeClass("-showNav");
  } else {
    $webView.addClass("-showNav");
  }
});

// page navigation via anchor link. on mobile the menu is placed inside the sidebar
// we want to toggle the nav when an anchor is clicked
const $mobilePageNavLinks = $(".mobilePageNav_link");
if ($mobilePageNavLinks.length) {
  $mobilePageNavLinks.each((_, link) => {
    $(link).click(() => {
      if ($webView.hasClass("-showNav")) {
        $webView.removeClass("-showNav");
      }
    });
  });
}
