import { SimpleEventEmitter } from "../lib/SimpleEventEmitter";

/**
 * The backing services that can send real-time socket updates via next-sockets.
 */
export enum EServiceName {
  /** next-services */
  Main = "main",
  /** next-actions */
  Actions = "actions",
  /** hive */
  Hive = "hive",
}

export interface ISyncMetadata<T = any> {
  // T = return type of parseData
  endpoint: string; // "checklists"
  action: string; // "taskSummary"
  parameters: { [key: string]: any }; // { date: "25-02-2019" }
  unseralizeData?: (data: any) => T; // extract data,
  mockData?: any;
  emitter?: SimpleEventEmitter;
  /** The name of the service to connect to. Next-Services is assumed when empty */
  serviceName?: EServiceName;
}
