// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ukMHi1RzVzTMLPLuFjK8{position:relative;height:100%;width:100%}.t1IO91Fv0bknC3RndbdN{height:100%}.adQcEK0GgJWsuxkhdLAV{position:relative;min-height:100px;height:100%}.N6hE7QHnGOYRu9GRmZWu{position:absolute;left:0;top:0;height:100%;width:100%}", "",{"version":3,"sources":["webpack://./../next-react/src/components/structure/PendingContent/styles.scss"],"names":[],"mappings":"AAGA,sBACE,iBAAA,CACA,WAAA,CACA,UAAA,CAGA,sBACE,WAAA,CAGF,sBACE,iBAAA,CACA,gBAAA,CACA,WAAA,CAGF,sBAEE,iBAAA,CACA,MAAA,CACA,KAAA,CACA,WAAA,CACA,UAAA","sourcesContent":["$theme: next;\n@import \"../../../scss/variables.scss\";\n\n.protectedArea {\n  position: relative;\n  height: 100%;\n  width: 100%;\n  // overflow: hidden;\n\n  &_container {\n    height: 100%;\n  }\n\n  &_localised {\n    position: relative;\n    min-height: 100px;\n    height: 100%;\n  }\n\n  &_fallback {\n    // HACK: ensures the content is shown correctly\n    position: absolute;\n    left: 0;\n    top: 0;\n    height: 100%;\n    width: 100%;\n    // z-index: 2;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"protectedArea": "ukMHi1RzVzTMLPLuFjK8",
	"protectedArea_container": "t1IO91Fv0bknC3RndbdN",
	"protectedArea_localised": "adQcEK0GgJWsuxkhdLAV",
	"protectedArea_fallback": "N6hE7QHnGOYRu9GRmZWu"
};
export default ___CSS_LOADER_EXPORT___;
