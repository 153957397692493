// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dlKpm4Aq0hHyiUB879G6{position:absolute;height:100vh;width:100%;display:flex;justify-content:center;align-items:center}", "",{"version":3,"sources":["webpack://./../next-react/src/components/handlers/RequireSessionPersistence/styles.scss"],"names":[],"mappings":"AACA,sBACE,iBAAA,CACA,YAAA,CACA,UAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA","sourcesContent":["$theme: next;\n.RequireSessionPersistence {\n  position: absolute;\n  height: 100vh;\n  width: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"RequireSessionPersistence": "dlKpm4Aq0hHyiUB879G6"
};
export default ___CSS_LOADER_EXPORT___;
