// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KbJbsD4gPoK77hqXmDzf{height:100%}.KbJbsD4gPoK77hqXmDzf.UHgmidbR3HBgnSDP9bAl{display:none}", "",{"version":3,"sources":["webpack://./../next-react/src/components/handlers/ErrorResolverHandler/styles.scss"],"names":[],"mappings":"AACA,sBAEE,WAAA,CAEA,2CACE,YAAA","sourcesContent":["$theme: next;\n.ErrorResolverHandler {\n  // HACK to ensure the app sits within a full height window\n  height: 100%;\n\n  &.-hide {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ErrorResolverHandler": "KbJbsD4gPoK77hqXmDzf",
	"-hide": "UHgmidbR3HBgnSDP9bAl"
};
export default ___CSS_LOADER_EXPORT___;
