// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TPO3K_jkTg5gaHNzYN_I:hover{cursor:pointer}", "",{"version":3,"sources":["webpack://./../next-react/src/components/generic/ImgUploader/styles.scss"],"names":[],"mappings":"AAEE,4BACE,cAAA","sourcesContent":["$theme: next;\n.ImgUploader {\n  &_preview:hover {\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ImgUploader_preview": "TPO3K_jkTg5gaHNzYN_I"
};
export default ___CSS_LOADER_EXPORT___;
