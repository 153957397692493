// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".K_eqdnvRlsaDNjY9Nm2p{text-align:center;font-style:italic}.V5MnCdzkt0lUDnU3DXbl{font-size:18px}.lSTU9RB95gvk7bWgPD0q{font-size:13px}", "",{"version":3,"sources":["webpack://./../next-react/src/components/generic/Caption/styles.scss"],"names":[],"mappings":"AACA,sBACE,iBAAA,CACA,iBAAA,CAEA,sBACE,cAAA,CAEF,sBACE,cAAA","sourcesContent":["$theme: next;\n.caption {\n  text-align: center;\n  font-style: italic;\n\n  &_title {\n    font-size: 18px;\n  }\n  &_description {\n    font-size: 13px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"caption": "K_eqdnvRlsaDNjY9Nm2p",
	"caption_title": "V5MnCdzkt0lUDnU3DXbl",
	"caption_description": "lSTU9RB95gvk7bWgPD0q"
};
export default ___CSS_LOADER_EXPORT___;
