import {
  ISerializedPatientLetter,
  PatientLetter,
} from "next-shared/src/models/PatientLetter";
import { mockHcps } from "./mockHcps";
import { mockPatients } from "./mockPatients";

export const jsonLetters: ISerializedPatientLetter[] = [
  {
    date: 1575957100,
    hcpId: parseInt(mockHcps[0].ehrId),
    id: 10,
    letterTemplateName: "Test letter 1",
    letterType: 6,
    patientEhrId: mockPatients[0].ehrPatients[0].ehrPatientId,
    released: false,
    staffMemberId: mockHcps[1].npServicesId,
    cmsLocationSlug: mockPatients[0].ehrPatients[0].cmsLocationSlug,
    ehrId: mockPatients[0].ehrPatients[0].ehrId,
  },
  {
    date: 1575957155,
    hcpId: parseInt(mockHcps[0].ehrId),
    id: 11,
    letterTemplateName: "Test letter 2",
    letterType: 1,
    patientEhrId: mockPatients[0].ehrPatients[0].ehrPatientId,
    released: false,
    staffMemberId: mockHcps[1].npServicesId,
    cmsLocationSlug: mockPatients[0].ehrPatients[0].cmsLocationSlug,
    ehrId: mockPatients[0].ehrPatients[0].ehrId,
  },
  {
    date: 1576453514,
    hcpId: parseInt(mockHcps[0].ehrId),
    id: 12,
    letterTemplateName: "Test letter 3",
    letterType: 6,
    patientEhrId: mockPatients[0].ehrPatients[0].ehrPatientId,
    released: false,
    staffMemberId: mockHcps[1].npServicesId,
    cmsLocationSlug: mockPatients[0].ehrPatients[0].cmsLocationSlug,
    ehrId: mockPatients[0].ehrPatients[0].ehrId,
  },
  {
    date: 1576453829,
    hcpId: parseInt(mockHcps[0].ehrId),
    id: 13,
    letterTemplateName: "Test letter 4",
    letterType: 3,
    patientEhrId: mockPatients[0].ehrPatients[0].ehrPatientId,
    released: false,
    staffMemberId: mockHcps[1].npServicesId,
    cmsLocationSlug: mockPatients[0].ehrPatients[0].cmsLocationSlug,
    ehrId: mockPatients[0].ehrPatients[0].ehrId,
  },
  {
    date: 1576453799,
    hcpId: parseInt(mockHcps[0].ehrId),
    id: 14,
    letterTemplateName: "Test letter 5",
    letterType: 6,
    patientEhrId: mockPatients[0].ehrPatients[0].ehrPatientId,
    released: false,
    staffMemberId: mockHcps[1].npServicesId,
    cmsLocationSlug: mockPatients[0].ehrPatients[0].cmsLocationSlug,
    ehrId: mockPatients[0].ehrPatients[0].ehrId,
  },
  {
    date: 1575957100,
    hcpId: parseInt(mockHcps[1].ehrId),
    id: 17,
    letterTemplateName: "Test letter 6",
    letterType: 6,
    patientEhrId: mockPatients[0].ehrPatients[0].ehrPatientId,
    released: false,
    staffMemberId: mockHcps[1].npServicesId,
    cmsLocationSlug: mockPatients[0].ehrPatients[0].cmsLocationSlug,
    ehrId: mockPatients[0].ehrPatients[0].ehrId,
  },
  {
    date: 1575957155,
    hcpId: parseInt(mockHcps[1].ehrId),
    id: 18,
    letterTemplateName: "Test letter 7",
    letterType: 1,
    patientEhrId: mockPatients[0].ehrPatients[0].ehrPatientId,
    released: false,
    staffMemberId: mockHcps[1].npServicesId,
    cmsLocationSlug: mockPatients[0].ehrPatients[0].cmsLocationSlug,
    ehrId: mockPatients[0].ehrPatients[0].ehrId,
  },
  {
    date: 1576453514,
    hcpId: parseInt(mockHcps[1].ehrId),
    id: 19,
    letterTemplateName: "Test letter 8",
    letterType: 6,
    patientEhrId: mockPatients[0].ehrPatients[0].ehrPatientId,
    released: false,
    staffMemberId: mockHcps[1].npServicesId,
    cmsLocationSlug: mockPatients[0].ehrPatients[0].cmsLocationSlug,
    ehrId: mockPatients[0].ehrPatients[0].ehrId,
  },
  {
    date: 1576453829,
    hcpId: parseInt(mockHcps[1].ehrId),
    id: 20,
    letterTemplateName: "Test letter 9",
    letterType: 3,
    patientEhrId: mockPatients[0].ehrPatients[0].ehrPatientId,
    released: false,
    staffMemberId: mockHcps[1].npServicesId,
    cmsLocationSlug: mockPatients[0].ehrPatients[0].cmsLocationSlug,
    ehrId: mockPatients[0].ehrPatients[0].ehrId,
  },
];

export const mockLetters = jsonLetters.map((x) => PatientLetter.unserialize(x));
