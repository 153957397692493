// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gnUIuMrZIhtJa7heAvSH{text-align:left}.gnUIuMrZIhtJa7heAvSH.g0I0zmqT0BaVFW1y_C_3{font-size:80%}", "",{"version":3,"sources":["webpack://./../next-react/src/components/atoms/PatientNote/styles.scss"],"names":[],"mappings":"AAGA,sBACE,eAAA,CACA,2CACE,aAAA","sourcesContent":["$theme: next;\n@import \"../../../scss/variables.scss\";\n\n.patientNote {\n  text-align: left;\n  &.-compact {\n    font-size: 80%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"patientNote": "gnUIuMrZIhtJa7heAvSH",
	"-compact": "g0I0zmqT0BaVFW1y_C_3"
};
export default ___CSS_LOADER_EXPORT___;
