import {
  ArrayMaxSize,
  ArrayMinSize,
  IsArray,
  IsInt,
  Max,
  Min,
} from "class-validator";
import { IFoyerTouchColors } from "../types/IFoyerMode";

export class FoyerTouchColors {
  @IsArray()
  @ArrayMinSize(3)
  @ArrayMaxSize(3)
  @IsInt({ each: true })
  @Min(0)
  @Max(255)
  high: number[];

  @IsArray()
  @ArrayMinSize(3)
  @ArrayMaxSize(3)
  @IsInt({ each: true })
  @Min(0)
  @Max(255)
  mid: number[];

  @IsArray()
  @ArrayMinSize(3)
  @ArrayMaxSize(3)
  @IsInt({ each: true })
  @Min(0)
  @Max(255)
  low: number[];

  static unserialize(touchColors: IFoyerTouchColors): FoyerTouchColors {
    const newFoyerTouchColors = new FoyerTouchColors();

    newFoyerTouchColors.high = touchColors.high;
    newFoyerTouchColors.mid = touchColors.mid;
    newFoyerTouchColors.low = touchColors.low;

    return newFoyerTouchColors;
  }

  serialize(): IFoyerTouchColors {
    return {
      high: this.high,
      mid: this.mid,
      low: this.low,
    };
  }
}
