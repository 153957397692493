import { IsEnum, IsString } from "class-validator";
import {
  smokingFrequencyLOINC,
  smokingQuantityLOINC,
  smokingStatusNHISLOINC,
  smokingStartDateCustomCode,
} from "next-shared/src/fhirUtil/fhirObservations/metricDefinitions/smoking";
import {
  alcoholFrequencyLOINC,
  alcoholQuantityLOINC,
  alcoholStatusLOINC,
  alcoholTotalScoreLOINC,
} from "next-shared/src/fhirUtil/fhirObservations/metricDefinitions/alcohol";
import {
  IBaseCarePlanSnapshot,
  BaseCarePlanSnapshot,
} from "./BaseCarePlanSnapshot";

export enum ELifestyle {
  Smoking = "Smoking",
  Alcohol = "Alcohol",
  Nutrition = "Nutrition",
  PhysicalActivity = "PhysicalActivity",
}

export interface ICarePlanLifestyleSnapshot extends IBaseCarePlanSnapshot {
  lifestyleSnapshotId: string;
  data: fhir4.Observation;
  lifestyleType: ELifestyle;
}

const findComponentByLOINCCode = (data: fhir4.Observation, loincCode: string) =>
  data.component?.find(
    (comp) => comp.extension?.[0]?.valueString === loincCode,
  );

/**
 * This class represents a snapshot of a care plan lifestyle.
 * A lifestyle is a record of an individual's habits, behaviors, and physical characteristics.
 * This includes things like exercise, diet, and sleep patterns.
 *
 * @class CarePlanLifestyleSnapshot
 * @extends BaseCarePlanSnapshot
 *
 * @property {string} lifestyleSnapshotId - The ID of the lifestyle snapshot (generated by uuidv4)
 * @property {fhir4.Observation} data - Data associated with the lifestyle snapshot
 * @property {ELifestyle} lifestyleType - The type of lifestyle associated with the snapshot (e.g. exercise, diet)
 *
 * @param {ICarePlanLifestyleSnapshot} data - Data used to create a new CarePlanLifestyleSnapshot instance
 *
 * @method serialize() - Serializes the instance into an object of type ICarePlanLifestyleSnapshot
 *
 * @example
 * const lifestyle = new CarePlanLifestyleSnapshot({
 *   lifestyleSnapshotId: uuidv4(),
 *   data: 'FHIR Observation object',
 *   lifestyleType: ELifestyle.Exercise
 * });
 */
export class CarePlanLifestyleSnapshot extends BaseCarePlanSnapshot {
  @IsString()
  lifestyleSnapshotId: string;

  data: fhir4.Observation;

  @IsEnum(ELifestyle)
  lifestyleType: ELifestyle;

  constructor(data: ICarePlanLifestyleSnapshot) {
    super(data);
    this.lifestyleSnapshotId = data.lifestyleSnapshotId;
    this.data = data.data;
    this.lifestyleType = data.lifestyleType;
  }

  getStatus(): string {
    let loincCode = "";
    if (this.lifestyleType === ELifestyle.Smoking) {
      loincCode = smokingStatusNHISLOINC;
    } else if (this.lifestyleType === ELifestyle.Alcohol) {
      loincCode = alcoholStatusLOINC;
    }
    if (loincCode) {
      const status = findComponentByLOINCCode(
        this.data,
        loincCode,
      )?.valueString;
      return status;
    }

    return null;
  }

  getQuantity(): string {
    let loincCode = "";

    if (this.lifestyleType === ELifestyle.Smoking) {
      loincCode = smokingQuantityLOINC;
    } else if (this.lifestyleType === ELifestyle.Alcohol) {
      loincCode = alcoholQuantityLOINC;
    }
    if (loincCode) {
      const quantity = findComponentByLOINCCode(
        this.data,
        loincCode,
      )?.valueString;
      return quantity;
    }

    return null;
  }

  getFrequency(): string {
    let loincCode = "";

    if (this.lifestyleType === ELifestyle.Smoking) {
      loincCode = smokingFrequencyLOINC;
    } else if (this.lifestyleType === ELifestyle.Alcohol) {
      loincCode = alcoholFrequencyLOINC;
    }
    if (loincCode) {
      const frequency = findComponentByLOINCCode(
        this.data,
        loincCode,
      )?.valueString;
      return frequency;
    }

    return null;
  }

  getSmokingStartDate() {
    if (this.lifestyleType === ELifestyle.Smoking) {
      const frequency = findComponentByLOINCCode(
        this.data,
        smokingStartDateCustomCode,
      )?.valueString;
      return frequency;
    }

    return null;
  }

  getAlcoholScore(): number {
    if (this.lifestyleType === ELifestyle.Alcohol) {
      const frequency = findComponentByLOINCCode(
        this.data,
        alcoholTotalScoreLOINC,
      )?.valueInteger;
      return frequency;
    }

    return null;
  }

  public serialize(): ICarePlanLifestyleSnapshot {
    return {
      ...super.serialize(),
      lifestyleSnapshotId: this.lifestyleSnapshotId,
      data: this.data,
      lifestyleType: this.lifestyleType,
    };
  }

  public toTemplate() {
    return {
      ...this.serialize(),
      ...super.toTemplate(),
      printingData: {
        status: this.getStatus(),
        quantity: this.getQuantity(),
        frequency: this.getFrequency(),
        ...(this.lifestyleType === ELifestyle.Alcohol && {
          scores: this.getAlcoholScore(),
        }),
        ...(this.lifestyleType === ELifestyle.Smoking && {
          smokingStartDate: this.getSmokingStartDate(),
        }),
      },
    };
  }
}
