import { IMetricDefinition } from "../IMetricDefinition";

export const hipWaistRatioLOINC = "POC-9845-9";
export const hipWaistRatioDisplayName = "Hip To Waist Ratio";
export const hipWaistRatioUOM = "";

const hipWaistRatioDefinition: IMetricDefinition = {
  type: "observation:WaistHipRatio",
  displayName: hipWaistRatioDisplayName,
  category: "vital-signs",

  components: [
    {
      codes: [hipWaistRatioLOINC],
      displayName: hipWaistRatioDisplayName,
    },
  ],
};

export default hipWaistRatioDefinition;
