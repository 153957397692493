import { MedicalResourceType } from "next-shared/src/types/types";

const typeToIcon: { [key: string]: string } = {
  [MedicalResourceType.BloodPressure]: "obs-blood-pressure",
  [MedicalResourceType.Weight]: "obs-weight",
  [MedicalResourceType.Height]: "obs-height",
  [MedicalResourceType.WaistCircumference]: "obs-hip-circumference",
  [MedicalResourceType.OxygenSaturation]: "obs-oxygen-saturation",
  [MedicalResourceType.RespiratoryRate]: "obs-respiratory-rate",
  [MedicalResourceType.Temperature]: "obs-temperature",
  [MedicalResourceType.Smoking]: "smoking",
  [MedicalResourceType.Alcohol]: "alcohol",
  [MedicalResourceType.ReasonForVisit]: "conditions",
  [MedicalResourceType.PatientForm]: "conditions",
  [MedicalResourceType.NoteToPatient]: "write",
};

export const mapObservationTypeToIcon = (obsType: string): string => {
  return typeToIcon[obsType] || "metrics";
};
