import { IMetricDefinition } from "../IMetricDefinition";
import { MedicalResourceType } from "next-shared/src/types/types";

/*
{
    "code": {
      "coding": [
        {
          "system": "http://loinc.org/",
          "code": "8331-1"
        }
      ]
    },
    "valueQuantity": {
      "value": 40,
      "unit": "cel(1 K)",
      "system": "http://unitsofmeasure.org/",
      "code": "cel(1 K)"
    }
  }
 */

export const bodyTemperatureLOINC = "8310-5";
export const oralTemperatureLOINC = "8331-1";
export const axillaryTemperatureLOINC = "8328-7";
export const memberaneTemperatureLOINC = "8333-7";
export const rectalTemperatureLOINC = "8332-9";

export const temperatureDisplayName = "Temperature";
export const temperatureUOM = "C";

const temperatureDefinition: IMetricDefinition = {
  type: MedicalResourceType.Temperature,
  displayName: temperatureDisplayName,
  category: "vital-signs",

  components: [
    {
      codes: [
        bodyTemperatureLOINC, // "Body temperature"
        oralTemperatureLOINC, // "Oral temperature"
        axillaryTemperatureLOINC, // "Axillary temperature"
        memberaneTemperatureLOINC, // "Tympanic membrane temperature"
        rectalTemperatureLOINC, // "Rectal temperature"
      ],
      displayName: temperatureDisplayName,
    },
  ],
};

export default temperatureDefinition;
