import { IMetricDefinition } from "../IMetricDefinition";
import { MedicalResourceType } from "next-shared/src/types/types";

export const bloodGlucoseLOINC = "POC-53049-3";
export const bloodGlucoseDisplayName = "Blood Glucose";
export const bloodGlucoseUOM = "mm[Hg]";

const bloodGlucoseDefinition: IMetricDefinition = {
  type: MedicalResourceType.BloodGlucose,
  displayName: bloodGlucoseDisplayName,
  category: "vital-signs",

  components: [
    {
      codes: [bloodGlucoseLOINC],
      displayName: bloodGlucoseDisplayName,
    },
  ],
};

export default bloodGlucoseDefinition;
