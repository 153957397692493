// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SAXh_T6EwvcZYqhk39Af{border-radius:3px;padding:3px 10px;display:flex;align-items:center}.SAXh_T6EwvcZYqhk39Af .nhRhdDzTLbuRks_TfEWw{flex:1}.SAXh_T6EwvcZYqhk39Af .nhRhdDzTLbuRks_TfEWw .P6T53UOiIZeDlDYeTY60{font-size:20px}.SAXh_T6EwvcZYqhk39Af .nhRhdDzTLbuRks_TfEWw .lkBpBbNj3ebMWeuwjB66{font-size:13px}.SAXh_T6EwvcZYqhk39Af .ItNGw_5yI7HGPtsUrrT_>span{display:flex;font-size:25px}.YIQGKaC_DHXIkNWb_nFL{background-color:rgba(20,20,20,.85);color:#fff;box-shadow:0 0 5px 0 #fff}.YIQGKaC_DHXIkNWb_nFL .ItNGw_5yI7HGPtsUrrT_>span{color:#fff}.P1Tj8w0oKA7jX7UUG9zY{background-color:rgba(255,255,255,.85);color:#222}.P1Tj8w0oKA7jX7UUG9zY .ItNGw_5yI7HGPtsUrrT_>span{color:#222}", "",{"version":3,"sources":["webpack://./../next-react/src/components/generic/Toast/components/ToastSlice/styles.scss"],"names":[],"mappings":"AACA,sBACE,iBAAA,CACA,gBAAA,CAEA,YAAA,CACA,kBAAA,CAEA,4CACE,MAAA,CAEA,kEACE,cAAA,CAEF,kEACE,cAAA,CAIJ,iDACE,YAAA,CACA,cAAA,CAGF,sBACE,mCAAA,CACA,UAAA,CACA,yBAAA,CAEA,iDACE,UAAA,CAIJ,sBACE,sCAAA,CACA,UAAA,CAEA,iDACE,UAAA","sourcesContent":["$theme: next;\n.ToastSlice {\n  border-radius: 3px;\n  padding: 3px 10px;\n\n  display: flex;\n  align-items: center;\n\n  .textContainer {\n    flex: 1;\n\n    .title {\n      font-size: 20px;\n    }\n    .description {\n      font-size: 13px;\n    }\n  }\n\n  .iconContainer > span {\n    display: flex;\n    font-size: 25px;\n  }\n\n  &_dark {\n    background-color: rgba(20, 20, 20, 0.85);\n    color: #fff;\n    box-shadow: 0 0 5px 0 white;\n\n    .iconContainer > span {\n      color: #fff;\n    }\n  }\n\n  &_light {\n    background-color: rgba(255, 255, 255, 0.85);\n    color: #222;\n\n    .iconContainer > span {\n      color: #222;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ToastSlice": "SAXh_T6EwvcZYqhk39Af",
	"textContainer": "nhRhdDzTLbuRks_TfEWw",
	"title": "P6T53UOiIZeDlDYeTY60",
	"description": "lkBpBbNj3ebMWeuwjB66",
	"iconContainer": "ItNGw_5yI7HGPtsUrrT_",
	"ToastSlice_dark": "YIQGKaC_DHXIkNWb_nFL",
	"ToastSlice_light": "P1Tj8w0oKA7jX7UUG9zY"
};
export default ___CSS_LOADER_EXPORT___;
