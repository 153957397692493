import $ from "jquery";

$(".collapsible").each((index, element) => {
  const $el = $(element);
  $(".collapsible_toggle", $el).click(() => {
    // remove preview on first click
    $el.removeClass("-withPreview");
    // TODO - animate the max-height
    // toggle section open and closed
    if ($el.hasClass("-closed")) {
      $el.removeClass("-closed");
    } else {
      $el.addClass("-closed");
    }
  });
  $(".collapsible_open", $el).click(() => {
    $el.removeClass("-withPreview");
    $el.removeClass("-closed");
  });
});

// allow content to be revealed
$("[data-toggle]").each((index, element) => {
  const $el = $(element);
  const target = $el.data("toggle");
  const $target = $(`[data-toggle-target='${target}']`);

  $el.click(() => {
    $target.show();
    $el.hide();
  });
});
