import { useEffect, useState } from "react";
import { pushGaEvent } from "next-shared/src/util/googleTracking/eventGa";
import {
  BookingEventTriggers,
  IBookingGaEvent,
  IDataLayer,
} from "next-shared/src/types/IGaTracking";
import {
  BookingContext,
  toBookingStageText,
  BOOKING_STAGES,
} from "next-react/src/contexts/AppointmentBookingContext";
import { stageToStep } from "next-react/src/helpers/stageToStep";
import { useClient } from "../../useClient";
import { useRequiredContext } from "../../useRequiredContext";

/**
 * Trigger events based on set booking context properties
 */
export const useGoogleTrackBooking = () => {
  const client = useClient();

  const {
    stage,
    location,
    patientBookingForSelf,
    patientType,
    appointmentType,
    hcp,
    bookingSteps,
    bookingCompleted,
    skipReasonForVisit,
    skipPayment,
  } = useRequiredContext(BookingContext);

  // track the location selected for booking
  useEffect(() => {
    if (!location?.slug) return;

    pushGaEvent(BookingEventTriggers.Location, {
      stage,
      stageText: toBookingStageText(stage),
      np_location: location?.slug,
      userId: client?.auth?.session?.patientId ?? null,
      steps: bookingSteps,
      step: stageToStep(bookingSteps, stage),
    });
  }, [location]);

  // track if this appointment was for the user or someone else
  useEffect(() => {
    if (!patientBookingForSelf) return;

    pushGaEvent(BookingEventTriggers.BookingForSelf, {
      stage,
      stageText: toBookingStageText(stage),
      patientBookingForSelf,
      userId: client?.auth?.session?.patientId ?? null,
      steps: bookingSteps,
      step: stageToStep(bookingSteps, stage),
    });
  }, [patientBookingForSelf]);

  // track patient type selected
  useEffect(() => {
    if (!patientType) return;

    pushGaEvent(BookingEventTriggers.PatientType, {
      stage,
      stageText: toBookingStageText(stage),
      patientType,
      userId: client?.auth?.session?.patientId ?? null,
      steps: bookingSteps,
      step: stageToStep(bookingSteps, stage),
    });
  }, [patientType]);

  // track appointment type selected
  useEffect(() => {
    if (!appointmentType?.label) return;

    pushGaEvent(BookingEventTriggers.AppointmentType, {
      stage,
      stageText: toBookingStageText(stage),
      patientType,
      userId: client?.auth?.session?.patientId ?? null,
      steps: bookingSteps,
      step: stageToStep(bookingSteps, stage),
      appointmentType: appointmentType?.label,
      provider: hcp?.slug,
    });
  }, [appointmentType]);

  // track which provider selected
  useEffect(() => {
    if (!hcp?.slug) return;

    pushGaEvent(BookingEventTriggers.Provider, {
      stage,
      stageText: toBookingStageText(stage),
      patientType,
      userId: client?.auth?.session?.patientId ?? null,
      steps: bookingSteps,
      step: stageToStep(bookingSteps, stage),
      provider: hcp?.slug,
    });
  }, [hcp]);

  // skip reason for visit
  useEffect(() => {
    if (typeof skipReasonForVisit === "undefined") return;

    pushGaEvent(BookingEventTriggers.SkipReasonForVisit, {
      stage,
      stageText: toBookingStageText(stage),
      patientType,
      userId: client?.auth?.session?.id ?? null,
      steps: bookingSteps,
      step: stageToStep(bookingSteps, stage),
      appointmentType: appointmentType?.label,
      skipReasonForVisit,
    });
  }, [skipReasonForVisit]);

  // skip payment
  useEffect(() => {
    if (typeof skipPayment === "undefined") return;

    pushGaEvent(BookingEventTriggers.SkipPaymentDetails, {
      stage,
      stageText: toBookingStageText(stage),
      patientType,
      userId: client?.auth?.session?.patientId ?? null,
      steps: bookingSteps,
      step: stageToStep(bookingSteps, stage),
      appointmentType: appointmentType?.label,
      skipPayment,
    });
  }, [skipPayment]);

  // track stages show to user in a given step list
  useEffect(() => {
    const eventPayload: IBookingGaEvent = {
      stage,
      stageText: toBookingStageText(stage),
      patientType,
      userId: client?.auth?.session?.patientId ?? null,
      steps: bookingSteps,
      step: stageToStep(bookingSteps, stage),
      appointmentType: appointmentType?.label,
    };

    switch (stage) {
      case BOOKING_STAGES.OtherConcerns: {
        pushGaEvent(BookingEventTriggers.OtherConcerns, eventPayload);
        break;
      }
      case BOOKING_STAGES.Time: {
        pushGaEvent(BookingEventTriggers.TimeOfDay, eventPayload);
        break;
      }
      case BOOKING_STAGES.Personals: {
        pushGaEvent(BookingEventTriggers.PersonalDetails, eventPayload);
        break;
      }
      case BOOKING_STAGES.Review: {
        pushGaEvent(BookingEventTriggers.ReviewBooking, eventPayload);
        break;
      }
    }
  }, [stage]);

  useEffect(() => {
    if (!bookingCompleted) return;

    pushGaEvent(BookingEventTriggers.BookingComplete, {
      stage,
      stageText: toBookingStageText(stage),
      patientType,
      userId: client?.auth?.session?.id ?? null,
      steps: bookingSteps,
      step: stageToStep(bookingSteps, stage),
      appointmentType: appointmentType?.label,
      bookingComplete: bookingCompleted,
    });
  }, [bookingCompleted]);
};
