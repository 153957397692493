import * as React from "react";
import * as ReactDOM from "react-dom";

import { urlParamsToObject } from "next-shared/src/helpers/urlParamsToObject";

import { NetworkSearch } from "next-react/src/entry/NetworkSearch";
import { LocationListing } from "next-react/src/entry/LocationListing";
import { PasswordReset } from "next-react/src/entry/PasswordReset";
import { WebForm } from "next-react/src/entry/WebForm";
import {
  PatientPortalApp,
  IPatientPortalAppProps,
} from "next-react/src/entry/PatientPortalApp";
import { BookingWidget } from "next-react/src/entry/BookingWidget";
import { BookingSuggestions } from "next-react/src/entry/BookingSuggestions";
import {
  ManageAppointmentApp,
  IManageAppointmentAppProps,
} from "next-react/src/entry/ManageAppointmentApp";

import { getElementAttributes } from "./getElementAttributes";

const bookingSuccessForward = (slug: string) => {
  setTimeout(() => {
    window.location.href = `/locations/${slug}/actions/booking-success`;
  }, 1000);
};

const elements = document.querySelectorAll("[data-component]");
for (let i = 0; i < elements.length; i++) {
  const el = elements[i];
  const { component, ...data } = getElementAttributes(el);
  const urlParams = urlParamsToObject();
  // dynamic prop passing via either the URL or the data attributes
  const {
    appointmentTypeSlugs = undefined,
    locationSlug = undefined,
    ...props
  } = { ...data, ...urlParams };

  const getComponent = () => {
    switch (component) {
      case "BookingWidget":
        return (
          <BookingWidget
            locationSlug={locationSlug}
            onBookingSuccess={bookingSuccessForward}
            {...props}
          />
        );
      case "PopoverBookingWidget":
        const resetWindowHash = () => {
          window.location.hash = "";
        };
        return (
          <BookingWidget
            popover
            locationSlug={locationSlug}
            onBookingSuccess={bookingSuccessForward}
            onCompleteDismiss={resetWindowHash}
            onBack={resetWindowHash}
            {...props}
          />
        );
      case "BookingSuggestions":
        return (
          <BookingSuggestions
            appointmentTypeSlugs={appointmentTypeSlugs.split(",")}
            locationSlug={locationSlug}
            onBookingSuccess={bookingSuccessForward}
            {...props}
          />
        );
      case "PasswordReset":
        return <PasswordReset {...props} />;
      case "ManageAppointment":
        return (
          <ManageAppointmentApp
            {...(props as unknown as IManageAppointmentAppProps)}
          />
        );
      case "PatientPortal":
        return <PatientPortalApp {...(props as IPatientPortalAppProps)} />;
      case "NetworkSearch":
        return <NetworkSearch {...props} />;
      case "LocationListing":
        return <LocationListing {...props} />;
      case "WebForm": {
        const { successUrl, slug, submitUrl, dataId } = props as any;

        // support for manually defined forms
        let fields = null;
        if (dataId) {
          try {
            fields = JSON.parse(document.getElementById(dataId).innerHTML);
          } catch (err) {
            console.error(err);
          }
        }
        return (
          <WebForm
            slug={slug}
            fields={fields}
            successUrl={successUrl}
            submitUrl={submitUrl}
            submitLabel="Send"
            successTitle={"Your inquiry has been sent"}
            successMessage={
              "We will review and one of our team will get back to you shortly."
            }
          />
        );
      }
      default:
        console.warn(`Unknown component: ${component}`);
        return <div>Unknown component</div>;
    }
  };

  ReactDOM.render(<div>{getComponent()}</div>, el);
}
