import React, { useState } from "react";

import { AltButton, Button } from "../../generic/Button";

import styles from "./styles.scss";
import { cssComposer } from "../../../helpers/cssComposer";
import { Popover } from "../../generic/Popover";
import {
  ButtonWithOptions,
  IButtonOptionsSection,
} from "../../generic/ButtonWithOptions";
const css = cssComposer(styles, "Footer");

export interface IFooterAction {
  label: string;
  onClick: (args?: any) => any;
  disabled?: boolean;
  isSecondary?: boolean;
  popoverContent?: React.ReactNode;
  buttonOptions?: IButtonOptionsSection[];
}

export interface IFooterProps {
  onAccept?: (args?: any) => any;
  acceptLabel?: string;
  acceptDisabled?: boolean;
  onCancel?: (args?: any) => any;
  cancelLabel?: string;
  actions?: Array<IFooterAction>;
  children?: any;
}

/**
 * Renders a basic footer content
 * Supports some common action out of the box (accept and cancel)
 * and custom actions
 */
export const Footer: React.FC<IFooterProps> = ({
  onAccept,
  acceptLabel = "Done",
  acceptDisabled = false,
  onCancel,
  cancelLabel = "Cancel",
  actions = [],
  children,
}) => {
  if (!(onCancel || onAccept || actions || children)) {
    return null;
  }
  const hasActions = !!onCancel || !!onAccept || !!actions?.length;

  const [openStates, setOpenStates] = useState<boolean[]>(
    Array(actions.length).fill(false),
  );

  const handleOpenChange = (index: number, isOpen: boolean) => {
    const newOpenStates = openStates.map((openingState: boolean, i: number) =>
      i === index ? isOpen : openingState,
    );
    setOpenStates(newOpenStates);
  };

  return (
    <div className={css("")} data-test="dialog-footer">
      <div className={css("content")} data-test="content">
        {children}
      </div>
      {hasActions && (
        <div className={css("actions")}>
          {onCancel && (
            <span data-test="cancel">
              <AltButton onClick={onCancel}>{cancelLabel}</AltButton>
            </span>
          )}
          {onAccept && (
            <span data-test="accept">
              <Button
                onClick={onAccept}
                disableOnSuccess={false}
                disabled={acceptDisabled}
              >
                {acceptLabel}
              </Button>
            </span>
          )}
          {!!actions?.length && (
            <span className={css("custom-actions")} data-test="custom-actions">
              {actions.map((action, i) => (
                <span key={i} data-test={`action-${i}`}>
                  {action?.disabled && action?.popoverContent ? (
                    <Popover
                      placement={{ position: "above" }}
                      open={openStates[i]}
                      closeHandler={() => handleOpenChange(i, false)}
                      target={
                        <div
                          onMouseEnter={() => handleOpenChange(i, true)}
                          onMouseLeave={() => handleOpenChange(i, false)}
                        >
                          <ButtonSwitch {...action} />
                        </div>
                      }
                    >
                      {action.popoverContent}
                    </Popover>
                  ) : (
                    <ButtonSwitch {...action} />
                  )}
                </span>
              ))}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export const ButtonSwitch: React.FC<IFooterAction> = (props: IFooterAction) => {
  const { buttonOptions, label, disabled, isSecondary, onClick } = props;
  if (buttonOptions && buttonOptions.length > 0) {
    return (
      <ButtonWithOptions
        onDefaultClick={onClick}
        disableOnSuccess={false}
        disabled={disabled}
        optionSections={buttonOptions}
      >
        {label}
      </ButtonWithOptions>
    );
  } else {
    return (
      <Button
        onClick={onClick}
        disableOnSuccess={false}
        disabled={disabled}
        variant={isSecondary ? "secondary" : "primary"}
      >
        {label}
      </Button>
    );
  }
};
