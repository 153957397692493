export interface IDataLayer extends IBookingGaEvent {
  event: string;
}

export interface IGTagManager extends Window {
  dataLayer?: IDataLayer[];
}

/**
 * GTM event names, SEO specialist will use these to map out the
 * user flow and drop off points
 */
export enum BookingEventTriggers {
  CovidSymptomsPrompt = "booking_CovidSymptomsPrompt",
  Location = "booking_Location",
  BookingForSelf = "booking_bookingForSelf",
  PatientType = "booking_PatientType",
  Provider = "booking_Provider",
  AppointmentType = "booking_AppointmentType",
  Login = "booking_Login",
  OtherConcerns = "booking_OtherConcerns",
  TimeOfDay = "booking_TimeOfDay",
  PersonalDetails = "booking_PersonalDetails",
  SkipReasonForVisit = "booking_SkipReasonForVisit",
  SkipPaymentDetails = "booking_SkipPaymentDetails",
  ReviewBooking = "booking_ReviewBooking",
  BookingComplete = "booking_BookingComplete",
}

/**
 * Booking specific GTM payload variables
 * These give more context to the event that was fired off to GTM
 */
export interface IBookingGaEvent {
  stage: number;
  stageText: string;
  steps: number[];
  step: number;
  userId: string;
  covidProceed?: boolean;
  np_location?: string;
  patientBookingForSelf?: boolean;
  patientType?: string;
  appointmentType?: string;
  provider?: string;
  skipReasonForVisit?: boolean;
  skipPayment?: boolean;
  bookingComplete?: boolean;
}
