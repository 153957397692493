import * as React from "react";
import { useContext } from "react";
import { Modal as ResponsiveModal, ModalProps } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

import { TDialogSizes } from "next-shared/src/types/dialogs";

import { Icon } from "../../generic/Icon";
import { Footer, IFooterProps } from "../../abstract/Footer";

import styles from "./styles.scss";
import { cssComposer } from "../../../helpers/cssComposer";
const css = cssComposer(styles, "Modal");

export interface IModalProps extends ModalProps {
  size?: TDialogSizes;
  fullHeight?: boolean;
  appMode?: boolean;
  withNextBarInjection?: boolean;
}

export const ModalContext = React.createContext<{
  onClose?: () => void | null;
}>({});

const useBodyScrollLock = (isOpen: boolean) => {
  React.useLayoutEffect(() => {
    if (!isOpen) {
      document.ontouchmove = null;
      return;
    }
    document.ontouchmove = (e) => e;
    return () => {
      document.ontouchmove = null;
    };
  }, [isOpen]);
};

// Wrapper for a third party library
export const Modal: React.FC<IModalProps> = ({
  open,
  fullHeight = false,
  blockScroll = true,
  children,
  size,
  onClose = () => {},
  appMode,
  withNextBarInjection = true,
  ...rest
}) => {
  useBodyScrollLock(open && blockScroll);

  return (
    <ResponsiveModal
      center
      open={open}
      blockScroll={false}
      classNames={{
        root: css("root"),
        overlay: css("overlay", `-size-${size}`),
        modal: css(
          "modal",
          "-customized",
          `-size-${size}`,
          { "-appMode": appMode },
          { "-fullHeight": fullHeight },
        ),
      }}
      onClose={onClose}
      closeOnOverlayClick={!!onClose}
      showCloseIcon={false}
      {...rest}
    >
      {/* HACK styling for Helix modals */}
      <div
        data-test="content"
        className={[
          withNextBarInjection ? "nph" : "",
          css("content", {
            "-fullHeight": fullHeight,
          }),
        ].join(" ")}
      >
        <ModalContext.Provider value={{ onClose: onClose || (() => null) }}>
          {children}
        </ModalContext.Provider>
      </div>
    </ResponsiveModal>
  );
};

export interface IModalHeaderProps {}

export const ModalHeader: React.FC<IModalHeaderProps> = ({ children }) => {
  const { onClose } = useContext(ModalContext);

  return (
    <div className={css("header")} data-test="header">
      <div className={css("header_heading")}>
        <h3>{children}</h3>
      </div>
      <div
        className={css("close")}
        onClick={onClose ? onClose : () => {}}
        data-test="close"
      >
        <Icon name={"close"} />
      </div>
    </div>
  );
};

export interface IModalBodyProps {
  fullHeight?: boolean;
}

export const ModalBody: React.FC<IModalBodyProps> = ({
  fullHeight,
  children,
}) => (
  <div className={css("body", { "-fullHeight": fullHeight })} data-test="body">
    {children}
  </div>
);

export interface IModalFooterProps extends IFooterProps {}

export const ModalFooter: React.FC<IModalFooterProps> = (props) => (
  <div className={css("footer")} data-test="footer">
    <Footer {...props} />
  </div>
);
