import {
  ISerializedPatientLabResult,
  PatientLabResult,
} from "next-shared/src/models/PatientLabResult";

import { mockHcps } from "./mockHcps";
import { mockPatients } from "./mockPatients";

export const serializedLabResults: ISerializedPatientLabResult[] = [
  {
    id: 1,
    name: "Lab result 1",
    patientEhrId: mockPatients[0].ehrPatients[0].ehrPatientId,
    hcpId: parseInt(mockHcps[0].ehrId),
    date: 1576453799,
    artefactClassification: 3,
    documentClassification: 2,
    artefactType: 13,
    documentId: 3,
    released: false,
    staffMemberId: mockHcps[0].npServicesId,
    cmsLocationSlug: mockPatients[0].ehrPatients[0].cmsLocationSlug,
    ehrId: mockPatients[0].ehrPatients[0].ehrId,
  },
  {
    id: 2,
    name: "Lab result 2",
    patientEhrId: mockPatients[0].ehrPatients[0].ehrPatientId,
    hcpId: parseInt(mockHcps[0].ehrId),
    date: 1576453799,
    artefactClassification: 4,
    documentClassification: 3,
    artefactType: 13,
    documentId: 4,
    released: false,
    staffMemberId: mockHcps[0].npServicesId,
    cmsLocationSlug: mockPatients[0].ehrPatients[0].cmsLocationSlug,
    ehrId: mockPatients[0].ehrPatients[0].ehrId,
  },
  {
    id: 3,
    name: "Lab result 3",
    patientEhrId: mockPatients[0].ehrPatients[0].ehrPatientId,
    hcpId: parseInt(mockHcps[0].ehrId),
    date: 1576453799,
    artefactClassification: 3,
    documentClassification: 2,
    artefactType: 13,
    documentId: 5,
    released: 1576453799,
    staffMemberId: mockHcps[0].npServicesId,
    cmsLocationSlug: mockPatients[0].ehrPatients[0].cmsLocationSlug,
    ehrId: mockPatients[0].ehrPatients[0].ehrId,
  },
  {
    id: 4,
    name: "Lab result 4",
    patientEhrId: mockPatients[0].ehrPatients[0].ehrPatientId,
    hcpId: parseInt(mockHcps[0].ehrId),
    date: 1576453799,
    artefactClassification: 4,
    documentClassification: 2,
    artefactType: 13,
    documentId: 6,
    released: 1576453799,
    staffMemberId: mockHcps[0].npServicesId,
    cmsLocationSlug: mockPatients[0].ehrPatients[0].cmsLocationSlug,
    ehrId: mockPatients[0].ehrPatients[0].ehrId,
  },
  {
    id: 5,
    name: "Lab result 5",
    patientEhrId: mockPatients[0].ehrPatients[0].ehrPatientId,
    hcpId: parseInt(mockHcps[1].ehrId),
    date: 1576453799,
    artefactClassification: 3,
    documentClassification: 2,
    artefactType: 13,
    documentId: 3,
    released: false,
    staffMemberId: mockHcps[1].npServicesId,
    cmsLocationSlug: mockPatients[0].ehrPatients[0].cmsLocationSlug,
    ehrId: mockPatients[0].ehrPatients[0].ehrId,
  },
  {
    id: 6,
    name: "Lab result 6",
    patientEhrId: mockPatients[0].ehrPatients[0].ehrPatientId,
    hcpId: parseInt(mockHcps[1].ehrId),
    date: 1576453799,
    artefactClassification: 4,
    documentClassification: 2,
    artefactType: 13,
    documentId: 4,
    released: false,
    staffMemberId: mockHcps[1].npServicesId,
    cmsLocationSlug: mockPatients[0].ehrPatients[0].cmsLocationSlug,
    ehrId: mockPatients[0].ehrPatients[0].ehrId,
  },
  {
    id: 7,
    name: "Lab result 7",
    patientEhrId: mockPatients[0].ehrPatients[0].ehrPatientId,
    hcpId: parseInt(mockHcps[1].ehrId),
    date: 1576453799,
    artefactClassification: 3,
    documentClassification: 2,
    artefactType: 13,
    documentId: 5,
    released: 1576453799,
    staffMemberId: mockHcps[1].npServicesId,
    cmsLocationSlug: mockPatients[0].ehrPatients[0].cmsLocationSlug,
    ehrId: mockPatients[0].ehrPatients[0].ehrId,
  },
  {
    id: 8,
    name: "Lab result 8",
    patientEhrId: mockPatients[0].ehrPatients[0].ehrPatientId,
    hcpId: parseInt(mockHcps[1].ehrId),
    date: 1576453799,
    artefactClassification: 4,
    documentClassification: 2,
    artefactType: 13,
    documentId: 6,
    released: 1576453799,
    staffMemberId: mockHcps[1].npServicesId,
    cmsLocationSlug: mockPatients[0].ehrPatients[0].cmsLocationSlug,
    ehrId: mockPatients[0].ehrPatients[0].ehrId,
  },
];

export const mockLabResults = serializedLabResults.map((x) =>
  PatientLabResult.unserialize(x),
);
