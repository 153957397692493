import {
  IBookingGaEvent,
  IDataLayer,
  IGTagManager,
} from "../../types/IGaTracking";

const windowGlobal: IGTagManager = window;

// set dataLayer, if not previously set
windowGlobal.dataLayer = windowGlobal?.dataLayer || [];

export const pushGaEvent = (
  eventName: string,
  params?: IBookingGaEvent,
): IDataLayer[] => {
  windowGlobal?.dataLayer.push({
    event: eventName,
    ...params,
  });

  return windowGlobal?.dataLayer;
};
