import {
  ArrayMaxSize,
  ArrayMinSize,
  IsArray,
  IsBoolean,
  IsEnum,
  IsInt,
  IsOptional,
  IsUrl,
  Max,
  Min,
  ValidateNested,
} from "class-validator";
import { EFoyerLogoMode, IFoyerMode } from "../types/IFoyerMode";
import { FoyerTouchColors } from "./FoyerTouchColors";

export class FoyerMode {
  @ValidateNested()
  touchColors: FoyerTouchColors;

  @IsOptional()
  @IsArray()
  @ArrayMinSize(3)
  @ArrayMaxSize(3)
  @IsInt({ each: true })
  @Min(0)
  @Max(255)
  backgroundColor?: number[];

  @IsOptional()
  @IsUrl()
  backgroundImageUrl?: string;

  @IsOptional()
  @IsBoolean()
  showArticle?: boolean;

  @IsOptional()
  @IsEnum(EFoyerLogoMode)
  logoType?: EFoyerLogoMode;

  static unserialize(foyerMode: IFoyerMode): FoyerMode {
    const newFoyerMode = new FoyerMode();

    newFoyerMode.touchColors = FoyerTouchColors.unserialize(
      foyerMode.touchColors,
    );
    newFoyerMode.backgroundColor = foyerMode.backgroundColor;
    newFoyerMode.backgroundImageUrl = foyerMode.backgroundImageUrl;
    newFoyerMode.showArticle = foyerMode.showArticle;
    newFoyerMode.logoType = foyerMode.logoType;

    return newFoyerMode;
  }

  serialize(): IFoyerMode {
    return {
      touchColors: this.touchColors.serialize(),
      backgroundColor: this.backgroundColor,
      backgroundImageUrl: this.backgroundImageUrl,
      showArticle: this.showArticle,
      logoType: this.logoType,
    };
  }
}
