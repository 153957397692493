/**
 * Takes a list of steps and figures out in which the stage is being shown
 * SOME MORE BACKGROUND:
 *  When we sending events to Google we need to know in which step a specific stage was shown.
 * @param steps
 * @param stage
 * @returns
 */
export const stageToStep = (steps: number[] = [], stage: number) => {
  return steps.indexOf(stage) + 1;
};
