/**
 * The intent of this file is to allow the front end to intercept the error thrown by our services and throw a custom error
 */

export enum ECustomErrorCodes {
  NEXT_ACCOUNT_DELETE_BIRTH_DATE_MISMATCH = "NEXT_ACCOUNT_DELETE_BIRTH_DATE_MISMATCH",
}

export interface ICustomError {
  code: ECustomErrorCodes;
  message?: string;
}

export const customErrors: Record<ECustomErrorCodes, ICustomError> = {
  [ECustomErrorCodes.NEXT_ACCOUNT_DELETE_BIRTH_DATE_MISMATCH]: {
    code: ECustomErrorCodes.NEXT_ACCOUNT_DELETE_BIRTH_DATE_MISMATCH,
    message:
      "An error occurred while trying to send the request to delete your account. Please make sure you entered the correct birth date and try again. If the problem persists, please contact your clinic.",
  },
};
