// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jjBzCHcVxX91ISThIkMn{margin:24px auto;border-bottom:1px solid #c8c8c8;max-width:400px}", "",{"version":3,"sources":["webpack://./../next-react/src/components/forms/Form/components/FormDivider/styles.scss"],"names":[],"mappings":"AAGA,sBACE,gBAAA,CACA,+BAAA,CACA,eAAA","sourcesContent":["$theme: next;\n@import \"../../../../../scss/variables.scss\";\n\n.FormDivider {\n  margin: $spacing-md auto;\n  border-bottom: 1px solid $color-gray;\n  max-width: 400px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FormDivider": "jjBzCHcVxX91ISThIkMn"
};
export default ___CSS_LOADER_EXPORT___;
