import * as _ from "lodash";

export const getElementAttributes = (
  el: Element,
): { [key: string]: string } => {
  const attributes = el.attributes;

  const data = {};
  for (let a = 0; a < attributes.length; a++) {
    const item = attributes.item(a);
    if (item.name.startsWith("data-")) {
      data[_.camelCase(item.name.replace("data-", ""))] = item.textContent;
    }
  }

  return data;
};
