import $ from "jquery";

const TRANSITION_TIME = 5000;
const FADE_TIME = 1000;

$("[data-carousel]").map((i, el) => {
  const $carousel = $(el);
  setInterval(() => {
    const $first = $carousel.children().first();
    $first.hide();
    $carousel.append($first);
    $first.fadeIn(FADE_TIME);
  }, TRANSITION_TIME);
});
