export enum EAppointmentStepIcons {
  Location = "clinics",
  Hcp = "doctor-solid",
  AppointmentType = "resources",
  OtherConcerns = "resources",
  Slot = "appointments-solid",
  Personals = "manage",
  // TODO get better icons
  Presentation = "manage",
  Payment = "manage",
  Review = "manage",
  Complete = "manage",
}
