import moment from "moment";
import {
  ChecklistTask,
  ISerializedChecklistTask,
} from "next-shared/src/models/ChecklistTask";

import { TChecklistCmsTaskFrequency } from "next-shared/src/types/TChecklistCmsTaskFrequency";

const dateToday = moment().format("YYYY-MM-DD");

export const mockOpsTasksSerialized: ISerializedChecklistTask[] = [
  {
    id: 125,
    cmsTaskId: "6903",
    date: dateToday,
    cmsTask: {
      id: "6903",
      title: "Perform EOD Admin - test",
      weight: 1,
      type: "numeric",
      relatedArticle: {
        slug: "opening",
        title: "Opening",
        anchor: "display-setup",
      },
      locationFeatures: [],
      timeOfDay: {
        title: "Opening - Start of Shift",
        slug: "opening-start-of-shift",
        startTime: "OPENING + 0",
        endTime: "OPENING + 60",
      },
      frequency: {
        slug: TChecklistCmsTaskFrequency.Daily,
        title: "Daily",
        duration: "1d",
      },
      category: {
        slug: "management",
        title: "Management",
        style: "",
      },
      minValue: 0,
      maxValue: 10,
    },
    title: "Perform EOD Admin",
    type: "numeric",
    completed: true,
    value: null,
    comment: null,
    imageKey: null,
    locationId: "678",
    weight: 1,
    dueDate: moment().add(1, "hour").unix(),
    startDate: moment().subtract(3, "hours").unix(),
    wasLate: null,
    completedAt: moment().unix(),
    updatedAt: 1570775085,
    createdAt: 1570775085,
  },
  {
    id: 113,
    cmsTaskId: "6881",
    date: dateToday,
    cmsTask: {
      id: "6881",
      title: "Ensure the Plate 4 Plate counter is working",
      weight: 1,
      type: "image",
      locationFeatures: [],
      relatedArticle: {
        slug: "equipment-and-safe-operating-procedures",
        title: "B. Equipment and safe operating procedures",
        anchor: "resources",
      },
      timeOfDay: {
        title: "Opening - Start of Shift",
        slug: "opening-start-of-shift",
        startTime: "OPENING + 0",
        endTime: "OPENING + 60",
      },
      frequency: {
        slug: TChecklistCmsTaskFrequency.Daily,
        title: "Daily",
        duration: "1d",
      },
      category: {
        slug: "management",
        title: "Management",
        style: "",
      },
      minValue: null,
      maxValue: null,
    },
    title: "Ensure the Plate 4 Plate counter is working",
    type: "boolean",
    completed: false,
    value: null,
    comment: null,
    imageKey: null,
    locationId: "678",
    weight: 1,
    dueDate: moment().add(1, "hour").unix(),
    startDate: moment().subtract(3, "hours").unix(),
    wasLate: null,
    completedAt: moment().unix(),
    updatedAt: 1570775085,
    createdAt: 1570775085,
  },
  {
    id: 114,
    cmsTaskId: "6735",
    date: dateToday,
    cmsTask: {
      id: "6735",
      title:
        "Are tortillas stored correctly and kept at room temperature during service? Upload an image",
      weight: 0,
      type: "image",
      relatedArticle: null,
      locationFeatures: [],
      timeOfDay: {
        title: "Opening - Start of Shift",
        slug: "opening-start-of-shift",
        startTime: "OPENING + 0",
        endTime: "OPENING + 60",
      },
      frequency: {
        slug: TChecklistCmsTaskFrequency.Monthly,
        title: "Monthly",
        duration: "1d",
      },
      category: {
        slug: "management",
        title: "Management",
        style: "",
      },
      minValue: null,
      maxValue: null,
    },
    title:
      "Are tortillas stored correctly and kept at room temperature during service? Upload an image",
    type: "image",
    completed: null,
    value: null,
    comment: null,
    imageKey: "https://picsum.photos/200/300",
    imageTmpUrl: "https://picsum.photos/200/300",
    locationId: "678",
    weight: 0,
    dueDate: moment().add(1, "hour").unix(),
    startDate: moment().subtract(3, "hours").unix(),
    wasLate: null,
    completedAt: null,
    updatedAt: 1570775085,
    createdAt: 1570775085,
  },
  {
    id: 115,
    cmsTaskId: "6243",
    date: dateToday,
    cmsTask: {
      id: "6243",
      title:
        "Check Esmeralda units have heated correctly and are at 90 degrees",
      weight: 3,
      type: "boolean",
      relatedArticle: null,
      locationFeatures: [],
      timeOfDay: {
        title: "Opening - Start of Shift",
        slug: "opening-start-of-shift",
        startTime: "OPENING + 0",
        endTime: "OPENING + 60",
      },
      frequency: {
        slug: TChecklistCmsTaskFrequency.Daily,
        title: "Daily",
        duration: "1d",
      },
      category: {
        slug: "management",
        title: "Management",
        style: "",
      },
      minValue: null,
      maxValue: null,
    },
    title: "Check Esmeralda units have heated correctly and are at 90 degrees",
    type: "boolean",
    completed: null,
    value: null,
    comment: null,
    imageKey: null,
    locationId: "678",
    weight: 3,
    dueDate: moment().add(1, "hour").unix(),
    startDate: moment().subtract(3, "hours").unix(),
    wasLate: null,
    completedAt: null,
    updatedAt: 1570775085,
    createdAt: 1570775085,
  },
  {
    id: 116,
    cmsTaskId: "4986",
    date: dateToday,
    cmsTask: {
      id: "4986",
      title: "Fill up back up bain marie",
      weight: 1,
      type: "boolean",
      relatedArticle: null,
      locationFeatures: [],
      timeOfDay: {
        title: "Opening - Start of Shift",
        slug: "opening-start-of-shift",
        startTime: "OPENING + 0",
        endTime: "OPENING + 60",
      },
      frequency: {
        slug: TChecklistCmsTaskFrequency.Daily,
        title: "Daily",
        duration: "1d",
      },
      category: {
        slug: "management",
        title: "Management",
        style: "",
      },
      minValue: null,
      maxValue: null,
    },
    title: "Fill up back up bain marie",
    type: "boolean",
    completed: null,
    value: null,
    comment: null,
    imageKey: null,
    locationId: "678",
    weight: 1,
    dueDate: moment().add(1, "hour").unix(),
    startDate: moment().subtract(3, "hours").unix(),
    wasLate: null,
    completedAt: null,
    updatedAt: 1570775085,
    createdAt: 1570775085,
  },
  {
    id: 117,
    cmsTaskId: "4999",
    date: dateToday,
    cmsTask: {
      id: "4999",
      title:
        "Turn on FOH Equipment - Greta (hot and cold section), Tortilla press, Quesadilla press, Salamander",
      weight: 0,
      type: "boolean",
      relatedArticle: null,
      locationFeatures: [],
      timeOfDay: {
        title: "Opening - Start of Shift",
        slug: "opening-start-of-shift",
        startTime: "OPENING + 0",
        endTime: "OPENING + 60",
      },
      frequency: {
        slug: TChecklistCmsTaskFrequency.Daily,
        title: "Daily",
        duration: "1d",
      },
      category: {
        slug: "management",
        title: "Management",
        style: "",
      },
      minValue: null,
      maxValue: null,
    },
    title:
      "Turn on FOH Equipment - Greta (hot and cold section), Tortilla press, Quesadilla press, Salamander",
    type: "boolean",
    completed: null,
    value: null,
    comment: null,
    imageKey: null,
    locationId: "678",

    weight: 0,
    dueDate: moment().add(1, "hour").unix(),
    startDate: moment().subtract(3, "hours").unix(),
    wasLate: null,
    completedAt: null,
    updatedAt: 1570775085,
    createdAt: 1570775085,
  },
  {
    id: 118,
    cmsTaskId: "5241",
    date: dateToday,
    cmsTask: {
      id: "5241",
      title:
        "Glass: Use warm soapy water and squeegee to clean the window, then use multipurpose and squeegee to ensure windows are clean and mark free.",
      weight: 0,
      type: "image",
      relatedArticle: null,
      locationFeatures: [],
      timeOfDay: {
        title: "Opening - Start of Shift",
        slug: "opening-start-of-shift",
        startTime: "OPENING + 0",
        endTime: "OPENING + 60",
      },
      frequency: {
        slug: TChecklistCmsTaskFrequency.Daily,
        title: "Daily",
        duration: "1d",
      },
      category: {
        slug: "management",
        title: "Management",
        style: "",
      },
      minValue: null,
      maxValue: null,
    },
    title:
      "Glass: Use warm soapy water and squeegee to clean the window, then use multipurpose and squeegee to ensure windows are clean and mark free.",
    type: "image",
    completed: null,
    value: null,
    comment: null,
    imageKey: "https://picsum.photos/200/300",
    imageTmpUrl: "https://picsum.photos/200/300",
    locationId: "678",
    weight: 0,
    dueDate: moment().add(1, "hour").unix(),
    startDate: moment().subtract(3, "hours").unix(),
    wasLate: null,
    completedAt: null,
    updatedAt: 1570775085,
    createdAt: 1570775085,
  },
  {
    id: 119,
    cmsTaskId: "5242",
    date: dateToday,
    cmsTask: {
      id: "5242",
      title: "Don't forget your toothbrush",
      weight: 0,
      type: "boolean",
      relatedArticle: null,
      locationFeatures: [],
      timeOfDay: {
        title: "Opening - Start of Shift",
        slug: "opening-start-of-shift",
        startTime: "OPENING + 0",
        endTime: "OPENING + 60",
      },
      frequency: {
        slug: TChecklistCmsTaskFrequency.Weekly,
        title: "Weekly",
        duration: "1d",
      },
      category: {
        slug: "management",
        title: "Management",
        style: "",
      },
      minValue: null,
      maxValue: null,
    },
    title: "Don't forget your toothbrush",
    type: "boolean",
    completed: null,
    value: null,
    comment: null,
    imageKey: null,
    locationId: "678",
    weight: 0,
    dueDate: moment().add(1, "hour").unix(),
    startDate: moment().subtract(3, "hours").unix(),
    wasLate: null,
    completedAt: null,
    updatedAt: 1570775085,
    createdAt: 1570775085,
  },
];

export const mockOpsTasks: ChecklistTask[] = mockOpsTasksSerialized.map((t) =>
  ChecklistTask.unserialize(t),
);
