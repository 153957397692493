// import $ from "jquery";
import "zenscroll";

import Sticky from "sticky-js";
// Stick item, such as sub menus
new Sticky("[sticky]");

import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";

import "./helpers/injectComponent";
import "./helpers/carousel";
import "./helpers/photoswipe";
import "./helpers/siteNav";
import "./helpers/collapsible";

if (document.getElementsByClassName("masonry").length > 0) {
  // @ts-ignore
  new Masonry(".masonry", {
    itemSelector: ".masonry_item",
  });
}

// article page layout
if (document.getElementsByClassName("articlesMasonry").length > 0) {
  const el = document.getElementsByClassName("articlesMasonry")[0];
  // @ts-ignore
  const articles = new Masonry(".articlesMasonry", {
    itemSelector: ".masonry_item",
    columnWidth: 340,
    fitWidth: true,
  });
  imagesLoaded(el, function () {
    articles.layout();
  });
}

// import { isMobile } from "./helpers/isMobile";

// carousel for article listing
// import Glide, { Controls, Autoplay } from '@glidejs/glide/dist/glide.modular.esm'
// new Glide('.glide').mount({ Controls, Autoplay });

// carousel for article listing
import Splide from "@splidejs/splide";

if (document.getElementsByClassName("articlesSplide").length) {
  const articlesSplide = new Splide(".articlesSplide", {
    type: "loop",
    padding: "5rem",
    autoplay: true,
    focus: "center",
    autoWidth: true,
    gap: "20px",
  });
  articlesSplide.mount();
}

if (document.getElementsByClassName("phoneSplide").length) {
  const phoneSplide = new Splide(".phoneSplide", {
    type: "loop",
    autoplay: true,
  });
  phoneSplide.mount();
}

if (document.getElementsByClassName("heroSectionSplide").length) {
  const heroSectionSplide = new Splide(".heroSectionSplide", {
    type: "fade",
    autoplay: true,
    arrows: false,
    pagination: false,
    width: "100%",
    rewind: true,
  });
  heroSectionSplide.mount();
}
