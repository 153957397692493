// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vmSbAAbMGkQfZ0REg31T{width:100%;display:flex;flex-direction:column;align-items:center;justify-content:space-evenly}", "",{"version":3,"sources":["webpack://./../next-react/src/components/search/Search/styles.scss"],"names":[],"mappings":"AACA,sBACE,UAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,4BAAA","sourcesContent":["$theme: next;\n.Search {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-evenly;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Search": "vmSbAAbMGkQfZ0REg31T"
};
export default ___CSS_LOADER_EXPORT___;
