// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".eNy_kXkn_pJmxRNB1izL{object-fit:cover;height:100%;width:100%;overflow:hidden;display:flex;justify-content:center}.eNy_kXkn_pJmxRNB1izL.GCLvqyVCcq2B3pLf1Crp{border-radius:50%}", "",{"version":3,"sources":["webpack://./../next-react/src/components/generic/Camera/components/CameraPreview/styles.scss"],"names":[],"mappings":"AACA,sBACE,gBAAA,CACA,WAAA,CACA,UAAA,CACA,eAAA,CACA,YAAA,CACA,sBAAA,CAEA,2CACE,iBAAA","sourcesContent":["$theme: next;\n.CameraPreview {\n  object-fit: cover;\n  height: 100%;\n  width: 100%;\n  overflow: hidden;\n  display: flex;\n  justify-content: center;\n\n  &.-round {\n    border-radius: 50%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CameraPreview": "eNy_kXkn_pJmxRNB1izL",
	"-round": "GCLvqyVCcq2B3pLf1Crp"
};
export default ___CSS_LOADER_EXPORT___;
