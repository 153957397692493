import { injectable } from "inversify";

import { ISyncModule, TSocketInfoMap } from "../modules/SyncModule";
import { TGuid } from "next-shared/src/types/guid";
import {
  EServiceName,
  ISyncMetadata,
} from "next-shared/src/types/ISyncMetadata";
import { createGuid } from "next-shared/src/helpers/guid";

@injectable()
export class MockSyncModule implements ISyncModule {
  private socketsInfo: TSocketInfoMap;

  constructor() {
    this.socketsInfo = {
      [EServiceName.Main]: {},
      [EServiceName.Actions]: {},
      [EServiceName.Hive]: {},
    };
  }

  public setUrls(url: { [name: string]: string }): void {}

  public subscribe(
    syncMetadata: ISyncMetadata,
    cb: (newData: any) => void,
  ): TGuid {
    return createGuid();
  }

  public unsubscribe(guid: TGuid): boolean {
    return true;
  }

  public getSockets() {
    return this.socketsInfo;
  }

  getSessionToken(
    serviceName: EServiceName = EServiceName.Main,
  ): string | null {
    return this.socketsInfo[serviceName]?.sessionToken ?? null;
  }

  setSessionToken(
    newSessionToken: string | null,
    serviceNames: EServiceName[] = [EServiceName.Main, EServiceName.Actions],
  ) {
    serviceNames
      .map((serviceName) => this.socketsInfo[serviceName])
      .forEach((socketInfo) => {
        socketInfo.sessionToken = newSessionToken;
      });
  }
}
