import { IMetricDefinition } from "../IMetricDefinition";
import { MedicalResourceType } from "next-shared/src/types/types";

export const respiratoryRateLOINC = "9279-1";
export const respiratoryRateDisplayName = "Respiratory Rate";
export const respiratoryRateUOM = "brpm";

const respiratoryRateDefinition: IMetricDefinition = {
  type: MedicalResourceType.RespiratoryRate,
  displayName: respiratoryRateDisplayName,
  category: "vital-signs",

  components: [
    {
      codes: [respiratoryRateLOINC],
      displayName: respiratoryRateDisplayName,
    },
  ],
};

export default respiratoryRateDefinition;
