import { useGoogleTrackBooking } from "next-react/src/hooks/googleTracking/useGoogleTrackBooking";
import * as React from "react";

/**
 * Placeholder component so that we can track booking specific events
 * @returns
 */
export const GoogleTrackBooking: React.FC = () => {
  useGoogleTrackBooking();
  return <></>;
};
