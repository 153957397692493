import * as React from "react";

import styles from "./styles.scss";
import { cssComposer } from "../../../helpers/cssComposer";
import { TColorVariants } from "next-shared/src/types/TColorVariants";
import { TErrorCollection } from "next-shared/src/types/TErrorCollection";
import _ from "lodash";
const css = cssComposer(styles, "Message");

export type MessageTitleProps = {
  children: any;
};

/**
 * Renders header of a Message
 */
export const MessageTitle: React.FC<MessageTitleProps> = ({ children }) => (
  <h4 className={css("title")}>{children}</h4>
);

export type MessageBodyProps = {
  children: any;
};

export interface IMessageBodyListProps {
  errorCollection: TErrorCollection | undefined;
}

/**
 *  Renders body of the message
 */
export const MessageBody: React.FC<MessageBodyProps> = ({ children }) => (
  <div className={css("body")}>{children}</div>
);

export const MessageBodyList: React.FC<IMessageBodyListProps> = ({
  errorCollection,
}) => {
  errorCollection = errorCollection || {};

  const errors = Object.keys(errorCollection).map((key) => {
    const value = errorCollection[key];
    if (_.isArray(value)) {
      return value.map((item) => (
        <li className={css("small-text")} key={item}>
          {item}
        </li>
      ));
    } else if (_.isObject(value)) {
      return (
        <li className={css("small-text", "unset-margin")} key={key}>
          <p className={css("small-text")}>{_.startCase(key)}</p>
          <p className={css("negative-margin")}>
            {MessageBodyList({ errorCollection: value as TErrorCollection })}
          </p>
        </li>
      );
    }
  });

  return (
    <div>
      <ul className={`${css("body-list")} ${css("list-margin")}`}>{errors}</ul>
    </div>
  );
};

export type MessageProps = {
  children: any;
  variant?: TColorVariants;
};

export const Message: React.FC<MessageProps> = ({ children, variant = "" }) => (
  <div className={css("", `-color-${variant}`)} data-test="message">
    {children}
  </div>
);

export const SuccessMessage: React.FC<MessageProps> = (props: MessageProps) => (
  <Message variant={TColorVariants.Success} {...props} />
);

export const ErrorMessage: React.FC<MessageProps> = (props: MessageProps) => (
  <Message variant={TColorVariants.Error} {...props} />
);

export const WarningMessage: React.FC<MessageProps> = (props: MessageProps) => (
  <Message variant={TColorVariants.Warning} {...props} />
);

export const InfoMessage: React.FC<MessageProps> = (props: MessageProps) => (
  <Message variant={TColorVariants.Info} {...props} />
);
