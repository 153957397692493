// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Lx8UbyRIBp941oalRDbo{min-height:400px;position:relative}.Lx8UbyRIBp941oalRDbo input[type=search]{width:100%}", "",{"version":3,"sources":["webpack://./../next-react/src/components/generic/ChooseLocation/styles.scss"],"names":[],"mappings":"AAGA,sBACE,gBAAA,CACA,iBAAA,CAEA,yCACE,UAAA","sourcesContent":["$theme: next;\n@import \"../../../scss/variables.scss\";\n\n.ChooseLocation {\n  min-height: 400px;\n  position: relative;\n\n  input[type=\"search\"] {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ChooseLocation": "Lx8UbyRIBp941oalRDbo"
};
export default ___CSS_LOADER_EXPORT___;
