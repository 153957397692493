import { IMetricDefinition } from "../IMetricDefinition";
import { MedicalResourceType } from "next-shared/src/types/types";

export const headCircumferenceLOINC = "8287-5";
export const headCircumferenceDisplayName = "Head Circumference";
export const headCircumferenceUOM = "cm";

const headCircumferenceDefinition: IMetricDefinition = {
  type: MedicalResourceType.HeadCircumference,
  displayName: headCircumferenceDisplayName,
  category: "vital-signs",

  components: [
    {
      codes: [headCircumferenceLOINC],
      displayName: headCircumferenceDisplayName,
    },
  ],
};

export default headCircumferenceDefinition;
