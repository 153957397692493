import {
  AppointmentType,
  IAppointmentType,
} from "next-shared/src/models/AppointmentType";
import {
  MOCK_APPOINTMENT_PATIENT_CONDITIONALS_FORM_KEY,
  MOCK_APPOINTMENT_FORM_SLUG,
} from "./mockFormSchemas";

export const mockAppointmentTypeNoAvailabilitySlug = "noAvailability";

export const mockAppointmentTypesSerializedLocA: IAppointmentType[] = [
  {
    label: "New Patient Appointment",
    slug: "newPatientAppointment",
    lengthMinutes: 15,
    lengthOverrides: {
      newPatient: 15,
    },
    visibility: "public",
    method: "physical",
    patientType: "new",
    description: "Recommended for New Patients",
    // payment: "optional",
    price: 20,
    rebate: 20,
    helixId: 1,
    deposit: 0,
    requireCard: false,
    reasonForVisitForm: MOCK_APPOINTMENT_FORM_SLUG,
    externalIdentifiers: {
      helix: "new-patient",
      bestPractice: "new-patient",
      helloHealth: "new-patient",
    },
  },
  {
    label: "Standard",
    slug: "standard",
    lengthMinutes: 10,
    lengthOverrides: {
      newPatient: 15,
    },
    visibility: "public",
    method: "physical",
    patientType: "all",
    description: "Standard short appointment",
    order: 2,
    // payment: "optional",
    price: 20,
    rebate: 10,
    helixId: 1,
    deposit: 0,
    requireCard: false,
    externalIdentifiers: {
      helix: "standard",
      bestPractice: "standard",
      helloHealth: "standard",
    },
  },
  {
    label: "Extended",
    slug: "extended",
    lengthMinutes: 30,
    lengthOverrides: {
      newPatient: 15,
    },
    visibility: "public",
    method: "physical",
    patientType: "all",
    description: "Blah",
    order: 0,
    // payment: "optional",
    price: 20,
    rebate: 0,
    helixId: 1,
    deposit: 0,
    requireCard: false,
    reasonForVisitForm: MOCK_APPOINTMENT_FORM_SLUG,
    externalIdentifiers: {
      helix: "extended",
      bestPractice: "extended",
      helloHealth: "extended",
    },
  },
  {
    label: "Return consult",
    slug: "returnConsult",
    lengthMinutes: 35,
    lengthOverrides: {
      newPatient: 15,
    },
    visibility: "public",
    method: "physical",
    patientType: "returning",
    description: "Standard short appointment",
    order: 2,
    // payment: "optional",
    price: 20,
    rebate: 20,
    helixId: 1,
    deposit: 0,
    requireCard: false,
    externalIdentifiers: {
      helix: "standard",
      bestPractice: "standard",
      helloHealth: "standard",
    },
  },
  {
    lengthMinutes: 15,
    lengthOverrides: {
      newPatient: 15,
    },
    label: "Video consult (private)",
    slug: "videoConsult-private",
    description: "Ullamco irure ad labore nisi fugiat eiusmod.",
    method: "digital",
    patientType: "all",
    visibility: "public",
    order: 1,
    // payment: "preBooking",
    price: 50,
    deposit: 50,
    requireCard: true,
    rebate: 0,
    helixId: 1,
    reasonForVisitForm: MOCK_APPOINTMENT_FORM_SLUG,
    externalIdentifiers: {
      helix: "standard",
      bestPractice: "standard",
      helloHealth: "standard",
    },
  },
  {
    lengthMinutes: 15,
    lengthOverrides: {
      newPatient: 15,
    },
    label: "Video consult (bulk bill)",
    slug: "videoConsult-bulkBill",
    description: "Ullamco irure ad labore nisi fugiat eiusmod.",
    method: "digital",
    patientType: "all",
    visibility: "public",
    order: 1,
    // payment: "preBooking",
    price: 40,
    rebate: 40,
    helixId: 1,
    deposit: 0,
    requireCard: true,
    externalIdentifiers: {
      helix: "standard",
      bestPractice: "standard",
      helloHealth: "standard",
    },
  },
  {
    lengthMinutes: 15,
    lengthOverrides: {
      newPatient: 15,
    },
    label: "Video consult (gap)",
    slug: "videoConsult-gap",
    description: "Ullamco irure ad labore nisi fugiat eiusmod.",
    method: "digital",
    patientType: "all",
    visibility: "public",
    order: 1,
    // payment: "preBooking",
    price: 50,
    rebate: 40,
    deposit: 50,
    requireCard: true,
    helixId: 1,
    reasonForVisitForm: MOCK_APPOINTMENT_FORM_SLUG,
    externalIdentifiers: {
      helix: "standard",
      bestPractice: "standard",
      helloHealth: "standard",
    },
  },
  {
    lengthMinutes: 15,
    lengthOverrides: {
      newPatient: 15,
    },
    label: "Physical or digital",
    slug: "either",
    description: "We ask the patient for their preference",
    method: "either",
    patientType: "all",
    visibility: "public",
    order: 1,
    // payment: "none",
    price: 0,
    rebate: 0,
    helixId: 1,
    deposit: 0,
    requireCard: false,
    externalIdentifiers: {
      helix: "standard",
      bestPractice: "standard",
      helloHealth: "standard",
    },
  },
  {
    lengthMinutes: 15,
    lengthOverrides: {
      newPatient: 15,
    },
    label: "Patient conditionals form",
    slug: "patient-conditionals",
    description: "Ullamco irure ad labore nisi fugiat eiusmod.",
    method: "digital",
    patientType: "all",
    visibility: "public",
    order: 1,
    price: 0,
    rebate: 0,
    deposit: 0,
    requireCard: false,
    helixId: 1,
    reasonForVisitForm: MOCK_APPOINTMENT_PATIENT_CONDITIONALS_FORM_KEY,
    externalIdentifiers: {
      helix: "standard",
      bestPractice: "standard",
      helloHealth: "standard",
    },
  },
];

// second location
export const mockAppointmentTypesSerializedLocB: IAppointmentType[] = [
  {
    lengthMinutes: 15,
    lengthOverrides: {
      newPatient: 15,
    },
    label: "Telehealth consult (deposit)",
    slug: "remoteConsult",
    description: "Ullamco irure ad labore nisi fugiat eiusmod.",
    method: "digital",
    patientType: "all",
    visibility: "public",
    order: 1,
    // payment: "preBooking",
    price: 50,
    rebate: 0,
    deposit: 30,
    requireCard: true,
    helixId: 1,
    externalIdentifiers: {
      helix: "standard",
      bestPractice: "standard",
      helloHealth: "standard",
    },
  },
  {
    label: "New patients",
    slug: "newPatients",
    lengthMinutes: 15,
    lengthOverrides: {
      newPatient: 15,
    },
    visibility: "public",
    method: "physical",
    patientType: "new",
    description: "Recommended for New Patients",
    // payment: "optional",
    price: 20,
    rebate: 20,
    helixId: 1,
    deposit: 0,
    requireCard: false,
    externalIdentifiers: {
      helix: "standard",
      bestPractice: "standard",
      helloHealth: "standard",
    },
  },
  {
    label: "No availability",
    slug: mockAppointmentTypeNoAvailabilitySlug,
    lengthMinutes: 15,
    lengthOverrides: {
      newPatient: 15,
    },
    visibility: "public",
    method: "physical",
    patientType: "new",
    description:
      "Eu consequat aliqua ut excepteur dolore non dolor eiusmod. Excepteur sit anim mollit culpa minim sint enim occaecat Lorem occaecat officia laboris amet deserunt.",
    // payment: "optional",
    price: 20,
    rebate: 20,
    helixId: 1,
    deposit: 0,
    requireCard: false,
    externalIdentifiers: {
      helix: "standard",
      bestPractice: "standard",
      helloHealth: "standard",
    },
  },
];

export const mockAppointmentTypesSerialized: IAppointmentType[] = [].concat(
  mockAppointmentTypesSerializedLocA,
  mockAppointmentTypesSerializedLocB,
);

export const mockAppointmentTypes: AppointmentType[] =
  mockAppointmentTypesSerialized.map((x) => AppointmentType.unserialize(x));
