export enum EDateFormat {
  ProdaRequest = "YYYY-MM-DD",
  HHDateFormat = "YYYY-MM-DD",
  HHTimeFormat = "HH:mm:ssZ",
  HHDateTimeFormat = "YYYY-MM-DDTHH:mm:ssZ",
  AustraliaDateFormat = "DD-MM-YYYY",
  AustraliaDateFormatSlash = "DD/MM/YYYY",
  Db4ReferenceDateFormat = "DDMMYYYYHHmmss",
  ImportScheduleDateFormat = "DD.MM.YYYY",
  ExportReportFormat = "YYYYMMDD",
  DefaultDateFormat = "MMM DD YYYY",
  DefaultDateTimeFormat = "MMM DD YYYY h:mma",
}
