import { IMetricDefinition } from "../IMetricDefinition";
import { MedicalResourceType } from "next-shared/src/types/types";

export const bmiLOINC = "39156-5";
export const bmiDisplayName = "BMI";
export const bmiUOM = "kg/m2";

const bmiDefinition: IMetricDefinition = {
  type: MedicalResourceType.BMI,
  displayName: bmiDisplayName,
  category: "vital-signs",

  components: [
    {
      codes: [bmiLOINC],
      displayName: bmiDisplayName,
    },
  ],
};

export default bmiDefinition;
